<template>
  <div ref="main" class="missionDetail" @click="expandAgentClick">
    <BackHeader title="任务详情" @onBack="handleBack"></BackHeader>
    <div class="missionSummary">
      <van-skeleton :row="5" :loading="!missionSubInfo.missionTypeMsg">
        <div>
          <div class="missionName">
            <span>{{ missionSubInfo.missionTypeMsg }}</span>
            <span class="line" v-if="missionSubInfo.missionEnumMsg">&nbsp;|&nbsp;</span>
            <span>{{ missionSubInfo.missionEnumMsg }}</span>
            <img
              v-if="missionSubInfo.cageMission"
              class="caged"
              src="@/assets/VehicleTask/caged.png"
              alt=""
            />
          </div>
          <div class="missionTime">
            <div>
              <span
                v-if="missionSubInfo.missionStatus != 2 && missionSubInfo.missionStatus != 12"
                >{{ missionSubInfo.finishTime }}</span
              >
              <span
                :style="{
                  color: missionStatusEnum[missionSubInfo.missionStatus].color,
                }"
                >{{ missionStatusEnum[missionSubInfo.missionStatus].text }}</span
              >
            </div>
            <div class="operator" v-if="missionSubInfo.operateUserName">
              <img
                src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/missionDetail/operator.png"
                alt=""
              />
              <span>{{ missionSubInfo.operateUserName }}</span>
            </div>
          </div>
        </div>
        <div>
          <div class="missionDestination">
            <span>{{ missionSubInfo.missionSubContent }}</span>
            <span>{{ missionSubInfo.missionContent }}</span>
          </div>
          <div v-if="[3, 4, 6].includes(missionSubInfo.missionStatus)" class="missionDuration">
            <div>
              <b></b>
              <span>{{ missionSubInfo.drivingDistance }}</span>
            </div>
            <div>
              <b></b>
              <span>{{ missionSubInfo.drivingDuration }}</span>
            </div>
          </div>
          <!-- 占位 -->
          <div v-else class="missionDuration">
            <div>
              <span></span>
            </div>
            <div>
              <span></span>
            </div>
          </div>
          <div class="parkingRecord" @click="onRecordClick">查看泊车记录 &gt;</div>
          <div class="expandTrigger" ref="expandTrigger">
            <div v-if="expand" class="expand-inner">
              <div>
                <b></b>
                <span>{{ missionSubInfo.vinId }}</span>
              </div>
              <div>
                <b></b>
                <span>{{ missionSubInfo.missionSubId }}</span>
              </div>
              <div>
                <b></b>
                <span>{{ orderNo || '-' }}</span>
              </div>
            </div>
          </div>
        </div>
      </van-skeleton>
    </div>
    <!-- 4个以内，撑开宽度 -->
    <MissionBar
      v-if="timePoints.length > 3"
      :list="timePoints"
      :status="missionSubInfo.missionStatus"
    ></MissionBar>
    <div class="lessMissionBar" v-else-if="timePoints.length > 1">
      <div class="barDashedBg"></div>
      <div class="barContent">
        <div class="timePoint" v-for="(item, index) in timePoints" :key="index">
          <div>
            <img
              v-if="index === 0"
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/timeBar_start.png"
              alt=""
            />
            <img
              v-else-if="
                index === timePoints.length - 1 && [3, 4, 6].includes(missionSubInfo.missionStatus)
              "
              :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/timeBar_status_${missionSubInfo.missionStatus}.png`"
              alt=""
            />
            <img
              v-else
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/timeBar_normal.png"
              alt=""
            />
          </div>
          <div>{{ item.content }}</div>
          <div v-if="item.endTime">{{ item.startTime }}~{{ item.endTime }}</div>
          <div v-else>{{ item.startTime }}</div>
        </div>
      </div>
    </div>
    <div v-if="showMap" class="missionPath">
      <div class="subTitle">
        <b></b>
        <span>行驶轨迹</span>
      </div>
      <div class="missionPath-content">
        <!-- 地图有两种状态，一个是全屏，一个是嵌套，复用同一个dom -->
        <div class="mapBox" :class="{ fullScreen }">
          <div
            :style="{ top: !fullScreen ? '10px' : `${appBarHeight + 10}px` }"
            class="mapScreenTrigger"
            :class="{ enterFullScreen: !fullScreen }"
            @click="changeFullScreen"
          >
            <img
              v-if="fullScreen"
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/map_screen_small.png"
              alt=""
            />
            <img
              v-else
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/map_screen_large.png"
              alt=""
            />
          </div>
          <div class="mapContainer" ref="mapContainer"></div>
          <div class="mapFixedContent">
            <div class="mapLegend">
              <img
                src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/map_legend_waypoint.png"
                alt=""
              />
              <span>停车点</span>
            </div>
            <div class="waypoint" v-if="fullScreen">
              <ul class="waypointList" v-if="waypoints && waypoints.length">
                <li
                  v-for="(item, index) in waypoints"
                  :key="index"
                  :class="{ active: index === activeWaypoint }"
                  @click="waypointClick(index, item)"
                >
                  <img
                    v-if="item.type === 0"
                    src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/map_detail_start.png"
                    alt=""
                  />
                  <img
                    v-else-if="item.type === 1"
                    src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/map_detail_end.png"
                    alt=""
                  />
                  <img
                    v-else
                    src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/map_waypoint.png"
                    alt=""
                  />
                  <div>
                    <div class="stationName">{{ item.name }}</div>
                    <div class="stationAddress">{{ item.address }}</div>
                    <div v-if="item.type === 9" class="parkingTime">
                      <div>{{ item.startTime }} ~ {{ item.endTime }}</div>
                      <div>
                        <img
                          v-if="item.isOvertime"
                          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/icon_parking.png"
                          alt=""
                        />
                        <img
                          v-else
                          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/icon_parking_normal.png"
                          alt=""
                        />
                        <span>{{ item.stopDuration }}</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="noneData" v-else>
                <img
                  src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/noneData.png"
                  alt=""
                />
                <span>暂无数据</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="speedTrend">
      <div class="subTitle">
        <b></b>
        <span>速度趋势</span>
      </div>
      <div
        class="noneData"
        v-show="!speedTrend.speedTimeStamps || speedTrend.speedTimeStamps.length === 0"
      >
        <img
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/noneData.png"
          alt=""
        />
        <span>暂无数据</span>
      </div>
      <div class="chartContainer" ref="chartContainer"></div>
      <div class="averageSpeed">
        <div>
          <b></b>
          <span>行驶平均时速 (不含停车)：</span>
          <span>{{ speedTrend.runAvgSpeed }}km/h</span>
        </div>
        <div>
          <b></b>
          <span>任务平均时速：</span>
          <span>{{ speedTrend.missionAvgSpeed }}km/h</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import BackHeader from './components/BackHeader.vue';
import MissionBar from './components/MissionBar.vue';
import moment from 'moment';
import { getMissionTimeLineDetail, getMissionRouteTrace, getSpeedTrend } from '@/api/api';

const ICON_CONFIG = {
  9: [
    {
      image:
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/map_waypoint.png',
      size: [20, 20],
      imageSize: [20, 20],
      offset: [-10, -10],
    },
    {
      image:
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/map_waypoint.png',
      size: [30, 30],
      imageSize: [30, 30],
      offset: [-15, -15],
    },
  ],
  0: [
    {
      size: [38, 38],
      imageSize: [38, 38],
      offset: [-19, -38],
      image:
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/map_start.png',
    },
    {
      size: [38, 38],
      imageSize: [38, 38],
      offset: [-19, -38],
      image:
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/map_start.png',
    },
  ],
  1: [
    {
      size: [38, 38],
      imageSize: [38, 38],
      offset: [-19, -38],
      image:
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/map_end.png',
    },
    {
      size: [38, 38],
      imageSize: [38, 38],
      offset: [-19, -38],
      image:
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/map_end.png',
    },
  ],
};

const missionStatusEnum = {
  1: { text: '执行中', color: '#436EFF' },
  2: { text: '待执行', color: '#8A8CA4' },
  3: { text: '完成', color: '#00C483' },
  4: { text: '取消', color: '#8A8CA4' },
  5: { text: '挂起', color: '#FFB800 ' },
  6: { text: '失败', color: '#ED3651' },
  12: { text: '未执行', color: '#8A8CA4' },
};

export default {
  name: 'MissionDetail',
  components: { MissionBar, BackHeader },
  data() {
    return {
      appBarHeight: parseInt(sessionStorage.getItem('_appBarHeight') ?? 0),
      defaultZoom: 14.05,
      singleCenter: null, // 假如只有一个站点时的中心位置
      expand: false,
      fullScreen: false,
      missionStatusEnum,
      activeWaypoint: null,
      missionSubId: null,
      orderNo: null,
      scrollTop: 0,
      showMap: true, // 挪车任务不显示地图
      map: null,
      parkingList: [1],
      missionSubInfo: {
        missionSubId: 17817,
        vin: 'LHTAY2A2XNYCLA001',
        vinId: 'X3P368',
        executeTime: '16:35',
        missionTypeMsg: '',
        missionEnumMsg: '',
        missionContent: '',
        missionSubContent: null,
        missionStatus: 3,
        missionStatusMsg: '',
        finishTime: '',
        drivingDistance: '0m',
        drivingDuration: '',
      },
      timePoints: [],
      waypoints: null,
      coordinatePoints: null,
      speedTrend: {
        runAvgSpeed: 0,
        missionAvgSpeed: 0,
        speedTimeStamps: [],
      },
    };
  },
  watch: {
    waypoints: {
      handler(newValue, oldValue) {
        this.drawPointOnMap(newValue, oldValue == null);
      },
      deep: true,
    },
    coordinatePoints: {
      handler(newValue, oldValue) {
        this.drawPathOnMap(newValue, oldValue == null);
      },
      deep: true,
    },
    speedTrend: {
      handler(newValue) {
        this.updateChart(newValue.speedTimeStamps);
      },
      deep: true,
    },
    activeWaypoint: {
      handler(newValue) {
        this.activeWaypointChange(newValue);
      },
    },
  },

  created() {
    this.showMap = this.$router.history.current.query?.missionTypeMsg !== '挪车任务';
  },

  mounted() {
    this.$loadingCircle.start();
    this.missionSubId = this.$router.history.current.query?.missionSubId;
    this.init();
    document.getElementById('app').addEventListener('scroll', this.windowScroll, true);
    document.getElementById('app').addEventListener('touchstart', this.touchstart, true);
    document.getElementById('app').addEventListener('touchmove', this.touchmove, true);
  },

  beforeDestroy() {
    document.getElementById('app').removeEventListener('scroll', this.windowScroll);
    document.getElementById('app').removeEventListener('touchstart', this.touchstart);
    document.getElementById('app').removeEventListener('touchmove', this.touchmove);
  },

  methods: {
    init() {
      this.initMap();
      this.initChart();
      this.getMissionDetail(); // 17817 17812
    },
    // 获取任务详情
    getMissionDetail() {
      const missionSubId = this.missionSubId;
      getMissionTimeLineDetail({ missionSubId }).then((res) => {
        this.missionSubInfo = res.missionSubInfo;
        this.timePoints = res.timePoints;
        this.orderNo = res.orderNo;
        this.getSpeedTrend();
        if ([1].includes(this.missionSubInfo.missionStatus)) {
          this.timeout = setTimeout(() => {
            if (this.showMap === true) this.getMissionRouteTrace();
            this.getMissionDetail();
          }, 1000 * 60);
        }
      });
    },

    // 获取任务路线
    getMissionRouteTrace() {
      const missionSubId = this.missionSubId;
      getMissionRouteTrace({ missionSubId }).then((res) => {
        this.waypoints = res.stopPoints;
        this.coordinatePoints = res.coordinatePoints;
      });
    },

    // 任务趋势
    getSpeedTrend() {
      const missionSubId = this.missionSubId;
      getSpeedTrend({ missionSubId }).then((res) => {
        this.speedTrend = res;
      });
    },

    // 地图行驶轨迹
    drawPathOnMap(coordninatePoints) {
      const path = coordninatePoints.map((d) => new this.AMap.LngLat(d.longitude, d.latitude));
      if (this.polyline) {
        this.polyline.setPath(path);
      } else if (path.length) {
        // 第一次加载时根据轨迹设置地图中心点
        this.polyline = new this.AMap.Polyline({
          strokeOpacity: 1,
          path,
          strokeWeight: 6,
          showDir: true,
          strokeColor: '#00C96D',
          zIndex: 1,
        });
        this.map.add(this.polyline);
      }
    },

    // 地图上画点
    drawPointOnMap(points, initial = false) {
      if (initial) {
        const path = points.map((d) => new this.AMap.LngLat(d.longitude, d.latitude));
        if (path.length === 1) {
          this.singleCenter = path[0];
          this.map.setZoomAndCenter(this.defaultZoom, this.singleCenter, true);
        } else {
          const polygon = new this.AMap.Polygon({ path });
          this.path = path;
          this.bounds = polygon.getBounds(); // 切换全屏时

          // IOS webview 奇葩问题
          if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            if (path.length > 2) {
              this.map.setBounds(this.bounds, false, [50, 30, 30, 30]);
            } else {
              this.map.setBounds(this.bounds, false, [50, 30, 30, 30]);
              const zoom = this.map.getZoom();
              this.map.setZoom(zoom - 0.01, false);
              // this.map.setBounds(this.bounds, false, [65, 65, 65, 65]);
            }
          } else {
            this.map.setBounds(this.bounds, false, [50, 30, 30, 30]);
          }
        }
      }
      if (this.markers) {
        this.map.remove(this.markers);
      }
      const markers = points.map((item, index) => {
        let marker;
        if (index === this.activeWaypoint) {
          marker = new this.AMap.Marker({
            icon: new this.AMap.Icon(ICON_CONFIG[item.type][1]),
            zIndex: 2,
            offset: ICON_CONFIG[item.type][1].offset,
            extData: Object.assign({}, item, { index }),
            position: [item.longitude, item.latitude],
            topWhenClick: true,
            label: {
              direction: 'top',
              content: this.getLabelContent(item),
            },
          });
          marker.setClickable(false);
          this.clickedMarker = marker;
        } else {
          marker = new this.AMap.Marker({
            icon: new this.AMap.Icon(ICON_CONFIG[item.type][0]),
            zIndex: 1,
            offset: ICON_CONFIG[item.type][0].offset,
            extData: Object.assign({}, item, { index }),
            position: [item.longitude, item.latitude],
            topWhenClick: true,
          });
        }
        marker.on('click', this.markerClick);
        return marker;
      });
      this.markers = markers;
      this.map.add(this.markers);
    },
    getLabelContent(extData) {
      return `<div class="customLabel ${extData.type === 9 ? 'customLabel-waypoint' : ''}">${
        extData.name
      }</div>`;
    },
    markerClick(event) {
      const marker = event.target;
      const extData = marker.getExtData();
      this.waypointClick(extData.index);
      if (!this.fullScreen) return;
      const container = document.querySelector('.waypoint');
      const currentLi = document.querySelectorAll('.waypointList > li')[extData.index];
      container.scrollTop = currentLi.offsetTop - container.clientHeight + currentLi.clientHeight;
    },

    // 初始化地图-调用接口，避免地图未加载
    initMap() {
      if (!this.showMap) return;
      AMapLoader.load({
        key: 'e618efe9bc3dbbbd1ddfa9d847f49d74',
        version: '2.0',
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map(this.$refs['mapContainer'], {
            mapStyle: 'amap://styles/whitesmoke',
            zoom: 16,
            center: [105.602725, 37.076636],
          });
          this.map.on('complete', () => {
            this.getMissionRouteTrace();
          });
          this.map.on('resize', () => {
            setTimeout(() => {
              if (this.singleCenter) {
                this.map.setZoomAndCenter(this.defaultZoom, this.singleCenter, true);
              } else {
                if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                  if (this.fullScreen) {
                    this.map.setBounds(
                      this.bounds,
                      true,
                      this.path.length <= 2 ? [120, 120, 120, 120] : [100, 100, 100, 100]
                    );
                  } else {
                    this.map.setBounds(this.bounds, true, [50, 50, 50, 50]);
                  }
                } else {
                  this.map.setBounds(this.bounds, true, [50, 30, 30, 30]);
                }
              }
            }, 200);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    initChart() {
      this.myChart = this.$echarts.init(this.$refs.chartContainer);
      const option = {
        grid: {
          top: 20,
          bottom: 20,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              type: 'solid',
              width: 1,
              color: '#B1B1B1',
            },
          },
          className: 'customTooltip',
          formatter: (params) => {
            return `
              <div>
                <div>${params[0].axisValue} 时速</div>
                <div>${params[0].data}km/h</div>
              </div>
            `;
          },
        },
        xAxis: {
          type: 'category',
          // type: "time",
          boundaryGap: false,
          // splitNumber: 10,
          // interval: 30,
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#E9E9ED',
              opacity: 1,
            },
          },
          // splitNumber: 2,
          axisTick: {
            show: true,
            length: 6,
            alignWithLabel: true,
            lineStyle: {
              width: 1,
              color: '#E9E9ED',
            },
          },
          minorTick: {
            show: true,
            splitNumber: 5,
            length: 4,
            lineStyle: {
              width: 1,
              color: '#E9E9ED',
            },
          },
          axisLabel: {
            margin: 8,
            color: '#9798AB',
            fontSize: 10,
          },
          data: [],
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#E9E9ED',
              opacity: 1,
            },
          },
          splitLine: {
            lineStyle: {
              color: '#E9E9ED',
              opacity: 1,
              width: 1,
            },
          },
          axisLabel: {
            margin: 4,
            color: '#9798AB',
            fontSize: 10,
          },
        },
        dataZoom: [
          {
            preventDefaultMouseMove: false,
            type: 'inside', //slider表示有滑动块的，inside表示内置的
            show: false,
            xAxisIndex: 0,
            start: 50,
          },
        ],
        series: [
          {
            data: [],
            type: 'line',
            smooth: true,
            showSymbol: false,
            symbol: 'circle',
            symbolSize: 6,
            areaStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(67,110,255,1)',
                },
                {
                  offset: 1,
                  color: 'rgba(67,110,255,0)',
                },
              ]),
            },
            itemStyle: {
              normal: {
                opacity: 0,
              },
              emphasis: {
                opacity: 1,
              },
            },
            lineStyle: {
              width: 2,
              color: '#436EFF',
            },
            // showAllSymbol: false, //true展示所有的标注点,false只展示跟X轴数据匹配的
          },
        ],
      };
      this.myChart.setOption(option);
    },
    updateChart(list) {
      this.myChart.setOption({
        xAxis: {
          data: list.map((item) => moment(item.timeStamp).format('HH:mm')),
        },
        series: [{ data: list.map((item) => item.speed) }],
      });
    },
    expandAgentClick(event) {
      if (event.target === this.$refs['expandTrigger']) {
        this.expand = !this.expand;
        return;
      }
      if (!this.$refs['expandTrigger'].contains(event.target)) {
        this.expand = false;
      }
    },
    changeFullScreen() {
      this.fullScreen = !this.fullScreen;
    },
    waypointClick(index, item) {
      this.activeWaypoint = index;
      if (item && item.longitude) {
        this.map.setCenter([item.longitude, item.latitude], true);
      }
    },
    // 当前点击marker的index变化后更新marker图标
    activeWaypointChange(index) {
      if (this.clickedMarker) {
        const extData = this.clickedMarker.getExtData();
        this.clickedMarker.setIcon(new this.AMap.Icon(ICON_CONFIG[extData.type][0]));
        this.clickedMarker.setLabel({
          offset: new this.AMap.Pixel(0, 0),
          direction: 'top',
          content: '',
        });
        this.clickedMarker.setzIndex(1);
        this.clickedMarker.setOffset(new this.AMap.Pixel(...ICON_CONFIG[extData.type][0].offset));
        this.clickedMarker.setClickable(true);
      }
      const marker = this.markers[index];
      const extData = marker.getExtData();
      marker.hide();
      marker.setLabel({
        offset: new this.AMap.Pixel(0, 0),
        direction: 'top',
        content: this.getLabelContent(extData),
      });
      marker.show();
      marker.setOffset(new this.AMap.Pixel(...ICON_CONFIG[extData.type][1].offset));
      marker.setClickable(false);
      marker.setzIndex(2);
      marker.setIcon(new this.AMap.Icon(ICON_CONFIG[extData.type][1]));
      this.clickedMarker = marker;
    },
    handleBack() {
      this.map?.destroy();
      this.$router.back(-1);
    },
    beforeDestroy() {
      this.map?.destroy();
    },
    touchstart(ev) {
      this.screenY = ev.changedTouches[0].screenY;
    },
    touchmove(ev) {
      if (ev.changedTouches[0].screenY > this.screenY && this.scrollTop === 0) {
        ev.preventDefault();
      }
    },

    windowScroll(e) {
      this.scrollTop = e.target.scrollTop;
    },

    onRecordClick() {
      this.$router.push({
        path: '/parkingRecord',
        query: {
          subMissionId: this.missionSubId,
          missionStatus: this.missionSubInfo.missionStatus,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.missionDetail {
  font-size: initial;
  background: #f6f7f8;

  .missionSummary {
    padding: 0 16px;
    margin: 12px 16px;
    margin-bottom: 20px;
    box-sizing: border-box;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 2px 10px 0px rgb(60 64 70 / 4%);

    > div {
      padding: 12px 0;

      &:nth-child(1) {
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        border-bottom: 1px solid #f1f1f1;

        .missionName {
          font-size: 16px;
          color: #000000;
          letter-spacing: 0;
          font-weight: 600;
          display: flex;
          align-items: center;

          .caged {
            margin-left: 8px;
            width: 20px;
            height: 20px;
          }
        }

        .missionTime {
          margin-top: 12px;
          font-size: 14px;
          letter-spacing: 0;
          font-weight: 600;

          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            margin-right: 6px;
          }

          .operator {
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              margin-right: 6px;
            }
            span {
              margin-right: 0;
              color: #9798ab;
            }
          }
        }
      }

      &:nth-child(2) {
        position: relative;

        .missionDestination {
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;

          span {
            font-size: 16px;
            color: #000000;
            letter-spacing: 0;
            font-weight: 600;

            &:nth-child(1) {
              font-size: 14px;
              font-weight: 400;
              margin-right: 4px;
            }
          }
        }

        .missionDuration {
          display: flex;
          font-size: 14px;
          color: #9798ab;
          letter-spacing: 0;
          font-weight: 400;
          margin-top: 12px;

          > div {
            display: flex;
            align-items: center;

            b {
              width: 20px;
              height: 20px;
            }

            &:nth-child(1) {
              margin-right: 8px;

              b {
                background: url(https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/icon_milage.png)
                  center no-repeat;
                background-size: contain;
              }
            }

            &:nth-child(2) {
              b {
                background: url(https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/icon_timeConsuming.png)
                  center no-repeat;
                background-size: contain;
              }
            }
          }
        }

        .parkingRecord {
          margin-top: 12px;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          color: rgba(70, 95, 253, 1);
        }

        .expandTrigger {
          width: 20px;
          height: 20px;
          position: absolute;
          right: 0px;
          bottom: 12px;
          background: url(https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/icon_expand.png)
            center no-repeat;
          background-size: contain;

          .expand-inner {
            position: absolute;
            width: 180px;
            height: 96px;
            bottom: -106px;
            right: -32px;
            background: rgba(0, 0, 0, 0.9);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 12px 10px;
            box-sizing: border-box;
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 0;
            font-weight: 400;
            z-index: 8;

            &::before {
              width: 0;
              height: 0;
              content: '';
              position: absolute;
              top: -10px;
              right: 28px;
              border-left: 14px solid transparent;
              border-right: 14px solid transparent;
              border-bottom: 14px solid rgba(0, 0, 0, 0.9);
            }

            b {
              width: 20px;
              height: 20px;
              margin-right: 2px;
            }

            > div {
              display: flex;
              align-items: center;

              &:nth-child(1) {
                b {
                  background: url(https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/icon_vehicle.png)
                    center no-repeat;
                  background-size: contain;
                }
              }

              &:nth-child(2) {
                b {
                  background: url(https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/icon_missionId.png)
                    center no-repeat;
                  background-size: contain;
                }
              }

              &:nth-child(3) {
                b {
                  background: url(https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/icon_orderId.png)
                    center no-repeat;
                  background-size: contain;
                }
              }
            }
          }
        }
      }
    }
  }

  .lessMissionBar {
    height: 80px;
    margin: 20px 0;
    box-sizing: content-box;

    .barDashedBg {
      width: calc(100% - 100px);
      transform: translate(50px, 16px);
      height: 8px;
      background-image: url(https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/timeBar_bg.png);
      background-size: 24px 4px;
      background-repeat: repeat-x;
    }

    .barContent {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;

      .timePoint {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 118px;

        div {
          &:nth-child(1) {
            background: #f6f7f8;

            img {
              width: 18px;
              height: 18px;
            }
          }

          &:nth-child(2) {
            font-size: 16px;
            color: #000000;
            letter-spacing: -0.7px;
            font-weight: 400;
            margin-top: 4px;
            margin-bottom: 2px;
            width: max-content;
          }

          &:nth-child(3) {
            font-size: 14px;
            color: #9798ab;
            letter-spacing: -0.61px;
            font-weight: 400;
            width: max-content;
          }
        }
      }
    }
  }

  .missionPath {
    padding: 0 15px;
    box-sizing: border-box;

    .mapBox {
      position: relative;
      background: #f5f5f5;

      &.fullScreen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        z-index: 10;

        .mapContainer {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100% - 274px + 16px);
        }

        .mapFixedContent {
          .waypoint {
            background: #ffffff;
            box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.1);
            border-radius: 16px 16px 0px 0px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              display: none;
            }

            height: 274px;

            ul {
              padding: 20px 6px 0;
              box-sizing: border-box;

              li {
                display: flex;
                align-items: center;
                padding: 10px 8px;
                box-sizing: border-box;

                &.active {
                  background: #f3f6ff;
                  border-radius: 8px;
                }

                img {
                  width: 18px;
                  height: 18px;
                  margin-right: 8px;
                }

                > div {
                  .stationName {
                    font-size: 16px;
                    color: #000000;
                    letter-spacing: 0;
                    font-weight: 600;
                  }

                  .stationAddress {
                    font-size: 14px;
                    color: #9798ab;
                    letter-spacing: 0;
                    font-weight: 400;
                  }

                  .parkingTime {
                    display: flex;
                    align-items: center;
                    width: max-content;
                    font-size: 14px;
                    color: #9798ab;

                    > div {
                      &:nth-child(2) {
                        margin-left: 8px;
                        display: flex;
                        align-items: center;

                        img {
                          margin-right: 0px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .noneData {
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            img {
              width: 130px;
              height: 130px;
            }

            span {
              font-size: 14px;
            }
          }
        }
      }

      .mapScreenTrigger {
        width: fit-content;
        height: fit-content;
        position: absolute;
        top: 10px;
        right: 8px;
        padding: 4px;
        z-index: 3;
        background: white;
        border-radius: 10px;
        box-sizing: content-box;

        img {
          width: 32px;
          height: 32px;
          display: block;
        }

        &.enterFullScreen {
          padding: 6px;

          img {
            width: 23px;
            height: 23px;
          }
        }
      }

      .mapFixedContent {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;

        .mapLegend {
          background: white;
          margin-left: 10px;
          margin-bottom: 10px;
          width: fit-content;
          font-size: 13px;
          color: #000000;
          letter-spacing: 0;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 7px 8px;
          border-radius: 8px;
          box-shadow: 0px 0px 8px 0px rgba(60, 64, 70, 0.1);

          img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }
      }
    }

    .mapContainer {
      height: 260px;
      border-radius: 8px;
      border: 1px solid rgba(212, 214, 221, 1);
      position: relative;

      ::v-deep .amap-copyright {
        display: none !important;
      }

      ::v-deep .amap-logo {
        display: none !important;
      }
    }
  }

  .speedTrend {
    padding: 20px 15px;
    box-sizing: border-box;
    position: relative;

    .noneData {
      background: white;
      position: absolute;
      top: 50px;
      left: 15px;
      width: calc(100% - 30px);
      height: calc(100% - 90px);

      z-index: 2;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-radius: 8px;

      img {
        width: 130px;
        height: 130px;
      }

      span {
        font-size: 14px;
      }
    }

    .chartContainer {
      height: 160px;
      background: white;
      border-radius: 8px;
    }

    .averageSpeed {
      background: white;
      padding: 16px 20px;
      box-sizing: border-box;

      > div {
        display: flex;
        margin-bottom: 6px;
        align-items: center;
        font-size: 13px;
        color: #000000;
        letter-spacing: 0;
        font-weight: 400;

        b {
          width: 8px;
          height: 8px;
          background: url(https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/timeBar_start.png)
            center no-repeat;
          background-size: contain;
          margin-right: 4px;
        }
      }
    }
  }

  .subTitle {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    b {
      background: #436eff;
      width: 3px;
      height: 16px;
      margin-right: 6px;
    }

    span {
      font-size: 16px;
      color: #000000;
      letter-spacing: 0;
      font-weight: 600;
    }
  }

  ::v-deep .customTooltip {
    background: rgba(0, 0, 0, 0.7) !important;
    border-radius: 4px;
    border: none !important;
    font-size: 20px;
    color: #ffffff !important;
    letter-spacing: 0.2px;
    font-weight: 600;
    z-index: 9 !important;
  }

  ::v-deep .customLabel {
    background: transparent;
    border: none;

    &.customLabel-waypoint {
      color: #fc7e36 !important;
    }
  }
}
</style>
